<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">{{showLang('com.op.edit.info')}}</div>
    <Form ref="form" :model="form" :label-width="80">
      <FormItem prop="parentId" :label="showLang('com.superior')">
        <Select :placeholder="showLang('save.select')" v-model="form.parentId">
          <Option :value="0">无</Option>
          <template v-for="(item, idx) in groups">
            <Option v-if="canSelect(item)" :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="name" :label="showLang('com.tab.title')">
        <Input ref="name" type="text" v-model="form.name" :placeholder="showLang('save.name')">
        </Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'TreeGroupEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    groups: {
      type: Array,
      default(){ return [];}
    }
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      form: {
        id: 0,
        name: '',
        parentId: 0,
      },
    }
  },
  computed: {
    ...mapState('group', ['rootTitle']),
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // console.log('modal open', this.item, this.groups)
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.parentId = this.item.data.parentId;
        }else{
          this.form.id = 0;
          this.form.name = '';
          this.form.parentId = this.item.data.id;
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
  },
  methods: {
    canSelect: function(grp){//选择上级分组时，不能选择自己和子孙分组，否则会成为死循环
      // console.log('can sel ', grp, this.isAdd, this.form.id)
      if(this.isAdd)return true;
      if(grp.id == this.form.id)return false; //上级分组是自己时，不能选
      if(this.isChild(grp.id, this.form.id))return false;//上级分组是子孙分组时，不能选
      return true;
    },
    isChild: function(cid, pid){//判断cid是否pid的子孙分组，包括跨多层分组关系
      let loop = true;
      while(loop){
        let grps = this.groups.filter(p => p.id == cid);
        if(grps.length == 0)return false;
        let grp = grps[0];
        if(grp.parentId == pid)return true;
        cid = grp.parentId;
      }
    },
    ok: async function () {
      // if(this.form.parentId <= 0){
      //   this.$Message.warning(this.showLang('save.superior.group'));
      //   return;
      // }
      this.$axios.post(`sys/auth/SaveGroup`, this.form).then(res => {
        if(res.code == 0){
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    cancel () {
      this.showModal = false;
      this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form{
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly{
  background: #eee
}
</style>