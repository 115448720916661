<template>
<div class="alarm-rule-container">
  <div class="alarm-data-area" ref="table1">
    <vxe-table resizable border show-overflow row-key :row-config="{isHover: true}" :key="tabRefresh" 
      :seq-config="{seqMethod}" :tree-config="{transform: true, expandAll: true, trigger: 'cell', rowField: 'id', parentField: 'parentId'}" 
      :data="showTreeList" :scroll-y="{enabled: false}" height="auto">
      <vxe-column type="seq" field="name" title="分区名称" tree-node  header-align="center" fixed="left"></vxe-column>
      <vxe-column field="id" title="分区ID" width="220" header-align="center"></vxe-column>
      <vxe-column  title="操作" width="320" fixed="right">
        <template #header>
          <Button v-if='funCodes(1000)' size="small" type="primary" style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</Button>
        </template>
        <template #default="params">
          <Button v-if='funCodes(1001)' size="small" type="primary" style="margin-right: 5px" @click="handleAdd(params)">{{showLang('com.op.add.sub.partition')}}</Button>
          <Button v-if='funCodes(1002)' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">{{showLang('com.op.edit')}}</Button>
          <Button v-if='funCodes(1003)' size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">{{showLang('com.op.del')}}</Button>
        </template>
      </vxe-column>
    </vxe-table>
    <ModalGroupEdit v-model="showEditModal" :item="editItem" :groups="treeList" @saved="itemSaved" />
  </div>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import ModalGroupEdit from './ModalGroupEdit'
export default {
  name: 'ConfigAreaIndex',
  components:{
    ModalGroupEdit,
  },
  props: {
  },
  data () {
    return {
      curTab: 'hour24',
      list: [],
      treeList: [],
      showTreeList: [],
      keyword: '',
      editItem: {isAdd: true, data: {}},
      showEditModal: false,
      showArgModal: false,
      tabRefresh: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
  },
  mounted: function(){
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    seqMethod({ row }) {
      return `${row.level}级：${row.name}`
    },
    handleAdd: function(params){
      this.editItem = {isAdd: true, data: params.row};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    itemSaved: function(){
      this.getList();
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name',params.row.name),
        onOk: async () => {
          this.$axios.post(`sys/auth/DeleteGroup`, {id: params.row.id}).then(res => {
            if(res.code == 0){
              this.getList();
            }
          });
        }
      });
    },
    getList: function(){
      this.$axios.post(`sys/auth/QueryGroup`, {}).then(res => {
        if(res.code == 0){
          this.$set(this, 'treeList', res.data);
          this.keywordBut();
        }
      });
    },
    keywordBut() {
      this.initTreeData();
      if (this.keyword == "") {
        this.$set(this, 'showTreeList', this.treeList);
        this.tabRefresh++
        return;
      }
      let arr = [];
      this.treeList.forEach(el => {
        if (el.name.indexOf(this.keyword) > -1) {
          arr.push(el);
        }
      });
      this.$set(this, 'showTreeList', arr);
      this.tabRefresh++
    },
    initTreeData: function () {
      this.treeList.forEach(el => {
        el.level = 1;
        let cs = this.treeList.filter(p => p.id == el.parentId);
        if (cs.length > 0) {
          el.level = cs[0].level + 1;
        }
      });
      this.treeList.forEach(el => {
        if (isNaN(el.level)) {
          this.initTreeData();
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area{
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  /* padding-left: 10px; */
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>